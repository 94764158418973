"use client";
import { useState, useContext } from "react";
import { useRouter } from "next/navigation";
import { ModalContext } from "@/app/context/modal";
import { DataContext } from "@/app/context/data";
import { addBookmark, removeBookmark } from "@/app/lib/bookmarks";
import styles from "./styles.module.css";
import TiStarOutline from "@/app/components/Assets/Icons/TiStarOutline.svg";

export default function BookmarkBtn({ daoId }: { daoId: string }) {
  const { handleModal } = useContext(ModalContext);

  const router = useRouter();
  const { bookmarkedDaos, setBookmarkedDaos } = useContext(DataContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const isBookmarked = bookmarkedDaos.includes(daoId);
  const tooltipClass = isBookmarked ? styles.remove : styles.add;

  const handleOnClick = async () => {
    // Disable button
    setIsDisabled(true);

    // Update bookmark status
    if (isBookmarked) {
      // Remove bookmark
      const code = await removeBookmark(daoId);
      if (code === 1) {
        // Update state
        const newBookmarks = bookmarkedDaos.filter((id) => id !== daoId);
        setBookmarkedDaos([...newBookmarks]);

        // Alert user of error
        handleModal("success", "Removed DAO from watchlist");

        // Refresh page
        router.refresh();
      } else if (code === -1) {
        // Prompt user to sign in
        handleModal("signin", null);
      } else {
        // Show error message
        handleModal("error", "Failed to remove DAO from watchlist");
      }
    } else {
      // Remove bookmark
      const code = await addBookmark(daoId);
      if (code === 1) {
        // Update state
        setBookmarkedDaos([...bookmarkedDaos, daoId]);

        // Alert user of success
        handleModal("success", "Added DAO to watchlist");

        // Refresh page
        router.refresh();
      } else if (code === -1) {
        // Prompt user to sign in
        handleModal("signin", null);
      } else {
        // Alert user of error
        handleModal("error", "Failed to add DAO to watchlist");
      }
    }

    // Enable button
    setIsDisabled(false);
  };

  return (
    <button
      id={`bookmark-${daoId}`}
      aria-label={isBookmarked ? "Remove bookmark" : "Add bookmark"}
      className={`${styles.bookmarkBtn} ${tooltipClass}`}
      onClick={handleOnClick}
      disabled={isDisabled}
    >
      <TiStarOutline
        fill={isBookmarked ? "var(--yellow)" : "none"}
        stroke={isBookmarked ? "var(--yellow)" : "var(--medium)"}
        width={20}
        height={20}
      />
    </button>
  );
}
