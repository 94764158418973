"use client";
import { useState, MouseEvent } from "react";
import styles from "./styles.module.css";
import { ITooltipCard } from "@/app/types";
import Pulse from "@/app/components/Pulse";

export default function TooltipCard(props: ITooltipCard) {
  const { label, tooltip, forceDisplay, showPulseAnimation } = props;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleOnMouseEnter = (e: MouseEvent) => {
    const width = e.currentTarget.getBoundingClientRect().width;
    const newShowTooltip = forceDisplay ? true : width < 280 ? false : true;
    setShowTooltip(newShowTooltip);
  };

  return (
    <div
      className={styles.tooltipCard}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {label}
      {showPulseAnimation && (
        <div className={styles.tooltipCardPulse}>
          <Pulse />
        </div>
      )}
      {showTooltip && (
        <div className={styles.tooltipCardTooltip}>
          <p>{tooltip}</p>
        </div>
      )}
    </div>
  );
}
